<div class="sidebar">
  <a routerLink="/analytics-dashboard">

    <div class="logo"><img src="../../../assets/Indie images/Indie Town.png" alt="logo"></div>
  </a>
  <div class="header-links">


    <a routerLink="/analytics-dashboard" routerLinkActive="active" >
      <span class="sidebar-icon">
        <img class="black-icon"  src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
        <img class="white-icon"  src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
      </span> Reporting </a>


    <a routerLink="/manage/interest" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/Manage Genre.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/sidebar/Manage Genre.svg" alt="" height="24" width="24">
      </span>Manage Genres</a>

    <a routerLink="/manage/community1" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Music Community.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Music Community.svg" alt="" height="24" width="24">
      </span>Manage Title</a>


    <a routerLink="/manage/community" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/Community.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/sidebar/Community.svg" alt="" height="24" width="24">
      </span>Manage Artist/Community</a>

    <div (mouseleave)="isDropDownVisible=false">
      <button class="dropdown-main grp-card" (click)="isDropDownVisible=true; "  
        [ngClass]="{ 'active': isManageReportedContentActive, 'blue-background': currentRoute === '/reported/view' ||  currentRoute === '/reported/comments' }">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/icons/SVGLOGO/Manage Reported Posts.svg" alt="" height="28"
            width="28">
          <img class="white-icon" src="../../../assets/icons/SVGLOGO/Manage Reported Posts.svg" alt="" height="28"
            width="28">
        </span> Manage Reported Content <span class="caret"></span>
      </button>

      <div *ngIf="isDropDownVisible" (mouseleave)="isDropDownVisible=false">
        <a routerLink="/reported/view" routerLinkActive="active" ><i class="fas fa-arrow-right" style="padding-right:10px"></i>Manage Reported Posts</a>
        <a routerLink="/reported/comments" routerLinkActive="active"><i class="fas fa-arrow-right" style="padding-right:10px"></i>Manage Reported Comments</a>
      </div>
    </div>

    <div (mouseleave)="isDropDownVisible1=false">
      <button class="dropdown-main grp-card" (click)="isDropDownVisible1=true; "  
        [ngClass]="{ 'active1': isManageReportedContentActive1, 'blue-background1': currentRoute === '/reported/edit' ||  currentRoute === '/reported/users' }">
        <span class="sidebar-icon">
          <img class="black-icon" src="../../../assets/icons/SVGLOGO/Report User.svg" alt="" height="24"
            width="24">
          <img class="white-icon" src="../../../assets/icons/SVGLOGO/Report User.svg" alt="" height="24"
            width="24">
        </span> Manage Reported Users <span class="caret"></span>
      </button>
      <div *ngIf="isDropDownVisible1" (mouseleave)="isDropDownVisible1=false">
        <a routerLink="/reported/edit" routerLinkActive="active"><i class="fas fa-arrow-right" style="padding-right:10px"></i>Manage Reported Users </a>
        <a routerLink="/reported/users" routerLinkActive="active"><i class="fas fa-arrow-right" style="padding-right:10px"></i>Blocked Users</a>
      </div>
    </div>







    <a routerLink="/generatee/code" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Referal.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Referal.svg" alt="" height="24" width="24">
      </span>Invite User</a>


    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/Chnage Password Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/Chnage Password Black.svg" alt="" height="24" width="24">
      </span>Change Password </a>

    <a class="dropdown-item admin-dropdown" routerLinkActive="active" id="logout" (click)="openModal(template)">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/Log Out Black.svg" alt="" height="24" width="24">
      </span>
      Logout</a>





  </div>
</div>

<!-- Logout fonfirm -->
<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
    <p>Are you sure you <br>want to logout?</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>

</ng-template>